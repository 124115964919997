
import {
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  GET_CLIENTS_FAILURE,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENT_FAILURE,
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  REGISTER_CLIENT_FAILURE,
  REGISTER_CLIENT_REQUEST,
  REGISTER_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
} from "../types/client";

const initialState = {
  clients: [],
  client: null,
  loading: true,
  error: {},
};

function clientReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CLIENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: payload,
        loading: false,
      };
    case GET_CLIENTS_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        client: payload,
        loading: false,
      };
    case GET_CLIENT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case REGISTER_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_CLIENT_SUCCESS:
      return {
        ...state,
        client: payload,
        loading: false,
      };
    // return {
    //   ...state,
    //   clients: [payload, ...state.clients],
    //   loading: false
    // };
    case REGISTER_CLIENT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        client: payload,
        loading: false,
      };
    case UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case DELETE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: state.clients.filter((client) => client.cyclopID !== payload),
        loading: false,
      };
    case DELETE_CLIENT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default clientReducer;
