import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";

const Navbar = ({ auth: { user }, logout }) => {
  const adminLevel = localStorage.getItem("adminLevel");
  return (
    <div className="">
      <nav
        id="navbar_main"
        className="mobile-offcanvas navbar navbar-expand-lg navbar-dark py-3 primary-bg-color shadow"
      >
        <div className="container px-5">
          <div className="offcanvas-header">
            <button className="btn-close float-end"></button>
          </div>
          <Link className="navbar-brand" to="/dashboard">
            CYCLOPS
          </Link>

          <ul className="navbar-nav"></ul>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item active">
              {" "}
              <Link className="nav-link" to="/dashboard">
                Dashboard
              </Link>{" "}
            </li>
            <li className="nav-item">
              {" "}
              <Link className="nav-link" to="/clients">
                Clients
              </Link>{" "}
            </li>
            <li className="nav-item">
              {" "}
              <Link className="nav-link" to="/receipts">
                Receipts
              </Link>{" "}
            </li>
            {user && adminLevel === "alpha" && (
              <li className="nav-item">
                <Link className="nav-link" to="/users">
                  Users
                </Link>
              </li>
            )}
            {/* <li className="nav-item">
              <Link className="nav-link" to="/payments">
                Payments
              </Link>
            </li> */}
            <li className="nav-item dropdown">
              <Link
                className="nav-link  dropdown-toggle"
                // eslint-disable-next-line
                to="#"
                data-bs-toggle="dropdown"
              >
                {" "}
                Account{" "}
              </Link>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <Link className="dropdown-item" to="/settings">
                    {" "}
                    Settings
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" onClick={logout} href="#!">
                    {" "}
                    Signout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
