import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_LOADED_FAILURE,
  USER_LOADED_SUCCESS,
} from "../types/auth";
import api from "../utils/api";
import { setAlert } from "./alert";

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: user,
      });
    }
  } catch (err) {
    dispatch({
      type: USER_LOADED_FAILURE,
    });
  }
};

// Login User
export const login = (userName, password) => async (dispatch) => {
  const body = { userName, password };
  dispatch({
    type: LOGIN_REQUEST,
  });
  try {
    const res = await api.post("/user/login", body);
    console.log(res.data);
    localStorage.setItem("user", JSON.stringify(res.data.user));
    localStorage.setItem("adminUsername", res.data.user.username);
    localStorage.setItem("token", res.data.token);
    localStorage.setItem("userLevel", res.data.userLevel);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(setAlert("We are in ):", "success"));
    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: LOGIN_FAILURE,
    });
    dispatch(setAlert("Invalid Credentials", "error"));
  }
};

// change password
export const changePassword = (formData) => async (dispatch) => {};

// forgot password
export const forgotPassword = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_PASSWORD_REQUEST,
    });
    const res = await api.patch("/forgot-password", formData, {
      headers: {
        "x-api-key": "7qe2F4XGNA2Kg9BCpSbWW9pNkLKkFJbNMXnbzof2",
      },
    });
    console.log(res.data);
    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: res.data,
    });
    setAlert(res.data.message, "success");
  } catch (err) {
    setAlert("There was a problem changing your password", "danger");
    dispatch({
      type: CHANGE_PASSWORD_FAILURE,
      payload: err,
    });
  }
};

// Logout
export const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("adminUsername");
  localStorage.removeItem("adminLevel");
  return {
    type: LOGOUT,
  };
};
