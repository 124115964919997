import {
  DELETE_RECEIPT_FAILURE,
  DELETE_RECEIPT_REQUEST,
  DELETE_RECEIPT_SUCCESS,
  GENERATE_RECEIPT_FAILURE,
  GENERATE_RECEIPT_REQUEST,
  GENERATE_RECEIPT_SUCCESS,
  GET_RECEIPTS_FAILURE,
  GET_RECEIPTS_REQUEST,
  GET_RECEIPTS_SUCCESS,
  GET_RECEIPT_FAILURE,
  GET_RECEIPT_REQUEST,
  GET_RECEIPT_SUCCESS,
} from "../types/receipt";

const initialState = {
  receipts: [],
  receipt: null,
  loading: true,
  isPaid: false,
  error: {},
};

function receiptReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_RECEIPTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RECEIPTS_SUCCESS:
      return {
        ...state,
        receipts: payload,
        loading: false,
      };
    case GET_RECEIPTS_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_RECEIPT_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case GET_RECEIPT_SUCCESS:
      return {
        ...state,
        receipt: payload,
        loading: false,
      };
    case GET_RECEIPT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GENERATE_RECEIPT_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case GENERATE_RECEIPT_SUCCESS:
      return {
        ...state,
        receipts: [...state.receipts, payload],
        loading: false,
      };
    case GENERATE_RECEIPT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case DELETE_RECEIPT_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case DELETE_RECEIPT_SUCCESS:
      return {
        ...state,
        receipts: state.receipts.filter((receipt) => receipt.id !== payload),
        loading: false,
      };
    case DELETE_RECEIPT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default receiptReducer;
