import { useState } from "react";
import PropTypes from "prop-types";
import AdminLayout from "../../components/layout/AdminLayout";
import { addUser } from "../../actions/user";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import { ArrowLeftCircle, ArrowRight } from "react-bootstrap-icons";

const UserCreate = ({ addUser }) => {
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("password");
  const [adminLevel, setAdminLevel] = useState("");

  const token = localStorage.getItem("token");
  const adminUsername = localStorage.getItem("adminUsername");

  return (
    <AdminLayout>
      <div className="container">
        <div className="mb-4 px-4">
          <div className="d-flex justify-content-between">
            <div className="fs-5 text-white my-2">Register new user</div>
            <Link to="/users" className="btn text-white btn-lg px-3 rounded-3">
              <ArrowLeftCircle size={30} />
              <MediaQuery query="(min-width: 992px)">
                <span className="mx-2">Back to list</span>
              </MediaQuery>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-5">
            <div className="card shadow rounded-4">
              <div className="card-body p-5">
                <form
                  action=""
                  onSubmit={(e) => {
                    e.preventDefault();
                    console.log({
                      token,
                      adminUsername,
                      fullName,
                      username,
                      email,
                      adminLevel,
                      password,
                    });
                    addUser({
                      token,
                      adminUsername,
                      fullName,
                      username,
                      email,
                      adminLevel,
                      password,
                    });
                    setFullName("");
                    setUsername("");
                    setEmail("");
                    setPassword("");
                  }}
                >
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control py-3"
                      name="fullName"
                      value={fullName}
                      required
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control py-3"
                      name="username"
                      value={username}
                      required
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control py-3"
                      name="email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div class="mb-3">
                    <label htmlFor="" className="form-label">
                      Choose admin level
                    </label>
                    <select
                      name="adminLevel"
                      id=""
                      className="form-select py-3"
                      onChange={(e) => setAdminLevel(e.target.value)}
                    >
                      <option value="alpha">Alpha</option>
                      <option value="beta">Beta</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <div className="row">
                      <div className="offset-md-6 col-md-6">
                        <div className="d-grid">
                          <button
                            className="btn primary-bg-color text-white p-4"
                            type="submit"
                          >
                            Register user{" "}
                            <ArrowRight className="mx-2" size={20} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

UserCreate.propTypes = {
  addUser: PropTypes.func.isRequired,
};

export default connect(null, { addUser })(UserCreate);
