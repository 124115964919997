import {
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  GET_CLIENTS_FAILURE,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENT_FAILURE,
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  REGISTER_CLIENT_FAILURE,
  REGISTER_CLIENT_REQUEST,
  REGISTER_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
} from "../types/client";
import api from "../utils/api";
import { setParams } from "../utils/setParams";
import { setAlert } from "./alert";

// Get clients
export const getClients = () => async (dispatch) => {
  dispatch({
    type: GET_CLIENTS_REQUEST,
  });
  try {
    const res = await api.get("/cyclops-clients", setParams());
    dispatch({
      type: GET_CLIENTS_SUCCESS,
      payload: res.data.allClients,
    });
  } catch (err) {
    dispatch({
      type: GET_CLIENTS_FAILURE,
      payload: {
        msg: "Unable to display clients for some reason.",
        status: false,
      },
    });
  }
};

// Get client
export const getClient = (id) => async (dispatch) => {
  dispatch({
    type: GET_CLIENT_REQUEST,
  });
  try {
    const res = await api.get(`/cyclops-client?clientId=${id}`, setParams());

    dispatch({
      type: GET_CLIENT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CLIENT_FAILURE,
      payload: { msg: "Unable to fetch client details", status: false },
    });
  }
};

// Add client
export const addClient = (formData) => async (dispatch) => {
  dispatch({
    type: REGISTER_CLIENT_REQUEST,
  });
  try {
    const res = await api.post("/client", formData, {
      headers: {
        "x-api-key": "7qe2F4XGNA2Kg9BCpSbWW9pNkLKkFJbNMXnbzof2",
      },
    });

    console.log(res);
    dispatch({
      type: REGISTER_CLIENT_SUCCESS,
      payload: res.data,
    });
    dispatch(setAlert("Client successfully registered", "success"));
  } catch (err) {
    dispatch({
      type: REGISTER_CLIENT_FAILURE,
      payload: { msg: err, status: false },
    });
    console.log(err);
    dispatch(setAlert("There was a problem adding this client", "error"));
  }
};

// Update client
export const updateClient =
  ({ clientID, ...formData }) =>
  async (dispatch) => {
    console.log({ clientID, ...formData });
    dispatch({
      type: UPDATE_CLIENT_REQUEST,
    });
    try {
      const res = await api.patch(
        `/cyclops-client?clientId=${clientID}`,
        formData,
        {
          headers: {
            "x-api-key": "7qe2F4XGNA2Kg9BCpSbWW9pNkLKkFJbNMXnbzof2",
          },
        }
      );
      console.log(res)
      dispatch({
        type: UPDATE_CLIENT_SUCCESS,
        payload: res.data,
      });
      dispatch(setAlert("Details updated", "success"));
    } catch (err) {
      dispatch({
        type: UPDATE_CLIENT_FAILURE,
        payload: { msg: `Unable to update ${formData}`, status: false },
      });
    }
  };

// Delete client
export const deleteClient = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_CLIENT_REQUEST,
    });
    await api.delete(`/cyclops-client`, {
      headers: {
        "x-api-key": "7qe2F4XGNA2Kg9BCpSbWW9pNkLKkFJbNMXnbzof2",
      },
      params: {
        clientId: id,
        token: localStorage.getItem("token"),
        adminUsername: localStorage.getItem("adminUsername"),
      },
    });

    dispatch({
      type: DELETE_CLIENT_SUCCESS,
      payload: id,
    });

    await dispatch(
      setAlert("Successfully removed from client list", "success")
    );
  } catch (err) {
    dispatch({
      type: DELETE_CLIENT_FAILURE,
      payload: { msg: "Unable to remove client from list", status: false },
    });
  }
};
