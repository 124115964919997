import { DELETE_CLIENT_SUCCESS } from "../types/client";
import {
  DELETE_RECEIPT_FAILURE,
  DELETE_RECEIPT_REQUEST,
  GENERATE_RECEIPT_FAILURE,
  GENERATE_RECEIPT_REQUEST,
  GENERATE_RECEIPT_SUCCESS,
  GET_RECEIPTS_FAILURE,
  GET_RECEIPTS_REQUEST,
  GET_RECEIPTS_SUCCESS,
  GET_RECEIPT_FAILURE,
  GET_RECEIPT_REQUEST,
  GET_RECEIPT_SUCCESS,
  RESEND_RECEIPT_FAILURE,
  RESEND_RECEIPT_REQUEST,
  RESEND_RECEIPT_SUCCESS,
} from "../types/receipt";
import api from "../utils/api";
import { setParams } from "../utils/setParams";
import { setAlert } from "./alert";

// client pay
export const clientPay = (formData) => async (dispatch) => {
  dispatch({
    type: GENERATE_RECEIPT_REQUEST,
  });
  try {
    const res = await api.post(`/paid`, formData, {
      headers: {
        "x-api-key": "7qe2F4XGNA2Kg9BCpSbWW9pNkLKkFJbNMXnbzof2",
      },
    });
    dispatch({
      type: GENERATE_RECEIPT_SUCCESS,
      payload: res.data,
    });

    dispatch(
      setAlert("Receipt was sent to client email successfully.", "success")
    );
  } catch (err) {
    dispatch({
      type: GENERATE_RECEIPT_FAILURE,
      payload: { msg: "Unable to generate receipt for client", status: false },
    });
    console.log(err);
  }
};

// Get all payments
// Get all receipts
export const getReceipts = () => async (dispatch) => {
  dispatch({
    type: GET_RECEIPTS_REQUEST,
  });
  try {
    const res = await api.get("/paid", setParams());

    dispatch({
      type: GET_RECEIPTS_SUCCESS,
      payload: res.data.allClients,
    });
  } catch (err) {
    dispatch({
      type: GET_RECEIPTS_FAILURE,
      payload: { msg: "Unable to get receipt information", status: false },
    });
  }
};

// Get receipt
export const getReceipt = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RECEIPT_REQUEST,
    });
    const res = await api.get(`/receipts/${id}`);

    dispatch({
      type: GET_RECEIPT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_RECEIPT_FAILURE,
      payload: { msg: "", status: false },
    });
  }
};

// Delete receipt
export const deleteReceipt = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_RECEIPT_REQUEST,
    });
    await api.delete(`/receipts/${id}`);

    dispatch({
      type: DELETE_CLIENT_SUCCESS,
      payload: id,
    });

    dispatch(setAlert("Receipt Removed", "success"));
  } catch (err) {
    dispatch({
      type: DELETE_RECEIPT_FAILURE,
      payload: { msg: "Unable to delete receipt", status: false },
    });
  }
};

// resend receipt
export const resendReceipt = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: RESEND_RECEIPT_REQUEST,
    });
    console.log(formData)
    const res = await api.post(`/resend-reciept`, formData, {
      headers: {
        "x-api-key": "7qe2F4XGNA2Kg9BCpSbWW9pNkLKkFJbNMXnbzof2",
      },
    });
    console.log(res.data)

    dispatch({
      type: RESEND_RECEIPT_SUCCESS,
      payload: res.data,
    });

    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch({
      type: RESEND_RECEIPT_FAILURE,
      payload: { msg: "Unable to generate receipt for client", status: false },
    });
    console.log(err);
  }
};
