import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminLayout from "../../components/layout/AdminLayout";
import { getReceipts } from "../../actions/receipt";
import ReceiptItem from "./ReceiptItem";
import Skeleton from "react-loading-skeleton";

const Receipts = ({ getReceipts, receipt: { receipts, loading } }) => {
  useEffect(() => {
    getReceipts();
  }, [getReceipts]);



  return (
    <AdminLayout>
      <div className="container">
        <div className="mb-4 px-4">
          <div className="d-flex justify-content-between">
            <span className="fs-4 text-white">Receipts</span>
          </div>
        </div>
        <div className="card shadow rounded-4">
          <div className="card-body p-5">
            {loading ? (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            ) : (
              receipts.map((receipt) => (
                <ReceiptItem key={receipt.paidID} receipt={receipt} />
              ))
            )}
          </div>
        </div>
      </div>
      
    </AdminLayout>
  );
};

Receipts.propTypes = {
  getReceipts: PropTypes.func.isRequired,
  receipt: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  receipt: state.receipt,
});

export default connect(mapStateToProps, { getReceipts })(Receipts);
