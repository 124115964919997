import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Person, Receipt } from "react-bootstrap-icons";
// DEleted Cash from the top line
import greetingTime from "greeting-time";
import AdminLayout from "../../components/layout/AdminLayout";
import { getClients } from "../../actions/client";
import { getReceipts } from "../../actions/receipt";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getAnalytics } from "../../actions/analytics";
import { formatCurrency } from "../../utils/formatCurrency";

const Dashboard = ({
  auth: { user },
  getClients,
  getAnalytics,
  analytics: { analytics },
  client: { clients, loading: loadingClient },
  getReceipts,
  receipt: { receipts, loading: loadingReceipt },
}) => {
  const [greeting, setGreeting] = useState();

  useEffect(() => {
    getClients();
    getReceipts();
    getAnalytics();
    setGreeting(greetingTime(new Date()));
  }, [getClients, getReceipts, getAnalytics]);

  return (
    user && (
      <AdminLayout>
        <div className="container">
          <div className="row mb-3">
            <div className="offset-md-3 col-md-9">
              <div className="mb-4 px-4 text-wrap">
                <strong
                  className="text-white fs-1"
                  style={{
                    fontWeight: "900",
                  }}
                >
                  {greeting}, {user && user.username}
                </strong>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 mb-3">
              <Link
                to="/clients"
                className="card shadow rounded-4 text-decoration-none"
                style={{
                  border: "none",
                }}
              >
                <div className="card-body p-5">
                  {loadingClient ? (
                    <div className="py-2">
                      <SkeletonTheme color="#ade8f4">
                        <Skeleton count={4} />
                      </SkeletonTheme>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between">
                      <Person size={70} className="text-success" />
                      <div>
                        <div className="fs-6 my-1 text-muted">Clients</div>
                        <strong className="fs-4 text-success">
                          {clients && clients.length}
                        </strong>
                      </div>
                    </div>
                  )}
                </div>
              </Link>
            </div>
            <div className="col-md-3 mb-3">
              <Link
                to="/receipts"
                className="card shadow rounded-4 text-decoration-none"
                style={{
                  border: "none",
                }}
              >
                <div className="card-body p-5 text-center">
                  {loadingReceipt ? (
                    <div className="py-2">
                      <SkeletonTheme color="" highlightColor="#ffe5d9">
                        <Skeleton count={4} />
                      </SkeletonTheme>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between">
                      <Receipt size={70} className="text-danger" />
                      <div>
                        <div className="fs-6 my-1 text-muted">Receipts</div>
                        <strong className="fs-4 text-danger">
                          {receipts && receipts.length}
                        </strong>
                      </div>
                    </div>
                  )}
                </div>
              </Link>
            </div>
            <div className="col-md-6 mb-3">
              <div
                className="card shadow rounded-4"
                style={{
                  border: "none",
                  marginBottom: "5rem",
                }}
              >
                <div className="card-body p-5">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-1 d-flex justify-content-between">
                        <span className="fs-6 text-muted">
                          Monthly paid amount
                        </span>
                        <span className="fs-6 text-success">
                          {analytics &&
                            formatCurrency.format(
                              analytics.monthlyPaidAmount
                            )}{" "}
                          UGX
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-1 d-flex justify-content-between">
                        <span className="fs-6 text-muted">Total Amount</span>
                        <span className="fs-6 text-warning">
                          {analytics &&
                            formatCurrency.format(analytics.totalAmount)}{" "}
                          UGX
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-1 d-flex justify-content-between">
                        <span className="fs-6 text-muted">Total Balance</span>
                        <span className="fs-6 text-warning">
                          {analytics &&
                            formatCurrency.format(analytics.totalBalance)}{" "}
                          UGX
                        </span>
                      </div>
                    </div>
                    <div className="mt-4 mb-1 text-muted">Recent payments</div>
                    <hr />
                    <table class="table table-striped table-borderless table-sm table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Amount (UGX)</th>
                          <th scope="col">Balance (UGX)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {analytics &&
                          analytics.clientsWhopaid &&
                          analytics.clientsWhopaid.map((client, index) => (
                            <tr key={index}>
                              <th>{client.clientname}</th>
                              <td>{formatCurrency.format(client.amount)}</td>
                              <td>{client.balance}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "5rem",
            }}
          >
            <div className="col-md-12"></div>
          </div>
        </div>
      </AdminLayout>
    )
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  getClients: PropTypes.func.isRequired,
  getReceipts: PropTypes.func.isRequired,
  getAnalytics: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  receipt: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  client: state.client,
  receipt: state.receipt,
  analytics: state.analytics,
});

export default connect(mapStateToProps, {
  getClients,
  getReceipts,
  getAnalytics,
})(Dashboard);
