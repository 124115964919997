import PropTypes from "prop-types";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Envelope, Trash } from "react-bootstrap-icons";
import { resendReceipt } from "../../actions/receipt";

const ReceiptItem = ({
  deleteReceipt,
  resendReceipt,
  auth,
  receipt: {
    paidID,
    recieptNo,
    clientName,
    chequeNo,
    clientEmail,
    balance,
    amount,
    datePaid,
    reason,
  },
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const token = localStorage.getItem("token");
  const adminUsername = localStorage.getItem("adminUsername");

  var nf = new Intl.NumberFormat();

  const handleResend = (e) => {
    e.preventDefault();
    resendReceipt({
      token,
      adminUsername,
      paidID,
      recieptNo,
      datePaid,
      clientName,
      amount,
      balance,
      reason,
      chequeNo,
      clientEmail,
    });
  };

  return (
    <>
      <a
        className="row text-decoration-none bg-light p-3 rounded-3 text-dark border mb-3"
        href="#!"
        onClick={handleShow}
      >
        <div className="col-md-4">
          <small className="text-muted">Emailed to</small>
          <br />
          <strong className="text-primary">{clientEmail}</strong>
        </div>
        <div className="col-md-4 text-right">
          <small className="text-muted">Date paid</small>
          <br />
          <span className="fs-6">{datePaid}</span>
        </div>
        <div className="col-md-4 text-right">
          <small className="text-muted">Reason</small>
          <br />
          <span className="fs-6">{reason}</span>
        </div>
        {/* <div className="col-md-6 fs-6 d-flex justify-content-end">
      {balance ? (
        <>
          <span className="mx-2 fs-4 text-danger">
            <ArrowDown /> {nf.format(balance)}
          </span>
        </>
      ) : null}
      {advance ? (
        <>
          <span className="mx-2 fs-4 text-success">
            <ArrowUp /> {nf.format(advance)}
          </span>
        </>
      ) : null}
    </div> */}
      </a>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-3 d-flex justify-content-between mb-3">
            <div>
              <strong>No.</strong>{" "}
              <span className="text-danger mx-2">{recieptNo}</span>
            </div>
            <div>
              <strong>Date paid:</strong>{" "}
              <span className="text-danger mx-2">{datePaid}</span>
            </div>
          </div>
          <div className="bg-light rounded p-3 mb-3">
            <div className="row">
              <div className="col-md-4">
                <strong htmlFor="" className="form-label">
                  Paid by
                </strong>
              </div>
              <div className="col-md-6">
                <span className="text-muted">{clientEmail}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <strong htmlFor="" className="form-label">
                  Cheque No
                </strong>
              </div>
              <div className="col-md-6">
                <span className="text-muted">
                  {chequeNo ? chequeNo : "N/a"}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <strong htmlFor="" className="form-label">
                  Amount
                </strong>
              </div>
              <div className="col-md-6">
                {amount ? (
                  <span className="text-danger">{nf.format(amount)} UGX</span>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <strong htmlFor="" className="form-label">
                  Balance
                </strong>
              </div>
              <div className="col-md-6">
                {balance ? (
                  <span className="text-danger">{nf.format(balance)} UGX</span>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <strong htmlFor="" className="form-label">
                  Reason
                </strong>
              </div>
              <div className="col-md-6">
                <span className="text-muted">{reason}</span>
              </div>
            </div>
          </div>
          <div className="mb-3 d-flex justify-content-between">
            <button className="btn btn-danger">
              <Trash size={20} />
              <span className="mx-2">Delete receipt</span>
            </button>
            <button
              className="btn primary-bg-color text-white py-3"
              onClick={handleResend}
            >
              <Envelope size={20} />
              <span className="mx-3">Resend to client</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

ReceiptItem.propTypes = {
  receipt: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  resendReceipt: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { resendReceipt })(ReceiptItem);
