import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateUser, deleteUser } from "../../actions/user";
import { Trash } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import { EditText } from "react-edit-text";
import { Modal } from "react-bootstrap";

const UserItem = ({ deleteUser, auth, user: { username, email, fullName, adminLevel }, updateUser }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updatedUsername, setUpdatedUsername] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updatedFullName, setUpdatedFullName] = useState("");

  const token = localStorage.getItem("token");
  const adminUsername = localStorage.getItem("adminUsername");

  const handleUpdate = ({ name, value, previousValue }) => {
    switch (name) {
      case "username":
        setUpdatedUsername(value);
        break;
      case "email":
        setUpdatedEmail(value);
        break;
      case "fullName":
        setUpdatedFullName(value);
        break;
      default:
        break;
    }
    updateUser({
      token,
      adminUsername,
      fullName: updatedFullName || fullName,
      email: updatedEmail || email,
      username: updatedUsername || username,
      adminLevel
    });
  };
  return (
    <>
      <a
        className="row text-decoration-none bg-light p-3 rounded-3 text-dark border mb-3"
        href="#!"
        onClick={handleShow}
      >
        <div className="col-md-4 fs-6">{fullName}</div>
        <div className="col-md-4 fs-6">{username}</div>
        <div className="col-md-4 fs-6">{email}</div>
      </a>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {username} details
            <br />
            <small className="text-muted" style={{ fontSize: "14px" }}>
              Click detail to edit.
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="mb-3 border p-3">
              <small htmlFor="" className="text-muted">
                Email
              </small>
              <br />
              <EditText
                name="email"
                value={updatedEmail || email}
                onChange={setUpdatedEmail}
                onSave={handleUpdate}
              />
            </div>
            <div className="mb-3 border p-3">
              <small className="text-muted">Name</small>
              <EditText
                name="fullName"
                value={updatedFullName || fullName}
                onChange={setUpdatedFullName}
                onSave={handleUpdate}
              />
            </div>
            {/* <div className="mb-3 border p-3">
              <small className="text-muted">Username</small>
              <EditText
                name="username"
                value={updatedUsername || username}
                onChange={setUpdatedUsername}
                onSave={handleUpdate}
              />
            </div> */}
            <div className="mb-3 d-flex justify-content-between">
              <div>
                <button
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                      if (result.value) {
                        deleteUser(username);
                        handleClose();
                      }
                    });
                  }}
                  className="btn py-3 btn-danger"
                  type="button"
                >
                  <Trash /> Delete user
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

UserItem.propTypes = {
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  updateUser,
  deleteUser
})(UserItem);
