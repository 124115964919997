import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  ArrowLeft,
  ArrowRight,
  Receipt,
  Trash,
  PersonCircle,
} from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";
import { EditText } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { updateClient, deleteClient } from "../../actions/client";
import { clientPay } from "../../actions/receipt";
import Swal from "sweetalert2";

const ClientItem = ({
  client: { cyclopID, clientname, email, phone, otherPhone, amount, address },
  deleteClient,
  updateClient,
  clientPay,
}) => {
  console.log({
    clientID: cyclopID,
    clientname,
    email,
    phone,
    otherPhone,
    amount,
    address,
  });
  const [show, setShow] = useState(false);
  const [generate, setGenerate] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleGenerate = () => {
    setGenerate(true);
  };
  const handleCloseGenerate = () => {
    setGenerate(false);
  };

  const [clientAmount, setClientAmount] = useState("");
  const [reason, setReason] = useState("");
  const [chequeNo, setChequeNo] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [datePaid, setDatePaid] = useState("");

  const [updatedClientName, setUpdatedClientName] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updatedPhone, setUpdatedPhone] = useState("");
  const [updatedOtherPhone, setUpdatedOtherPhone] = useState("");
  const [updatedAddress, setUpdatedAddress] = useState("");
  const [updatedAmount, setUpdatedAmount] = useState("");
  // format amount
  // var nf = new Intl.NumberFormat();

  const token = localStorage.getItem("token");
  const adminUsername = localStorage.getItem("adminUsername");

  // handle update
  const handleUpdate = (e, { name, value, previousValue }) => {
    e.preventDefault();
    switch (name) {
      case "clientname":
        setUpdatedClientName(value);
        break;
      case "email":
        setUpdatedEmail(value);
        break;
      case "phone":
        setUpdatedPhone(value);
        break;
      case "otherPhone":
        setUpdatedOtherPhone(value);
        break;
      case "address":
        setUpdatedAddress(value);
        break;
      case "amount":
        setUpdatedAmount(value);
        break;
      default:
        break;
    }
    updateClient({
      clientID: cyclopID,
      clientname: updatedClientName || clientname,
      email: updatedEmail || email,
      phone: updatedPhone || phone,
      otherPhone: updatedOtherPhone || otherPhone,
      address: updatedAddress || address,
      amount: updatedAmount || amount,
      adminUsername,
      token,
    });
  };

  const generateReceipt = (e) => {
    e.preventDefault();
    clientPay({
      token,
      adminUsername,
      clientID: cyclopID,
      amount: clientAmount,
      reason,
      chequeNo,
      datePaid,
      clientEmail: clientEmail || email,
    });
    handleClose();
  };
  return (
    <>
      <a
        className="row text-decoration-none bg-light p-3 rounded-3 text-dark border mb-3"
        onClick={handleShow}
        href="#!"
      >
        <div className="col-md-4 fs-6 d-flex justify-content-start">
          <div className="p-1">
            <PersonCircle size={40} className="text-muted" />
          </div>
          <div className="mx-3">
            <small className="text-muted">Name</small>
            <br />
            <strong>{clientname}</strong>
          </div>
        </div>
        <div className="col-md-4 fs-6">
          <small className="text-muted">Email</small>
          <br />
          {email}
        </div>
        <div className="col-md-4 text-muted">
          <small className="text-muted">Phone</small>
          <br />
          {phone}
        </div>
      </a>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {generate ? "Generate receipt" : "Details"} <br />
            <small className="text-muted" style={{ fontSize: "14px" }}>
              Click detail to edit.
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generate ? (
            <>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a
                      href="#!"
                      onClick={handleCloseGenerate}
                      className="text-decoration-none text-secondary"
                    >
                      <ArrowLeft /> Back to details
                    </a>
                  </li>
                </ol>
              </nav>
              <form onSubmit={generateReceipt}>
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Amount
                  </label>
                  <input
                    type="text"
                    name="clientAmount"
                    value={clientAmount}
                    onChange={(e) => setClientAmount(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Reason
                  </label>
                  <input
                    type="text"
                    name="reason"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Cheque No
                  </label>
                  <input
                    type="text"
                    name="chequeNo"
                    value={chequeNo}
                    onChange={(e) => setChequeNo(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Date paid
                  </label>
                  <input
                    type="date"
                    name="datePaid"
                    value={datePaid}
                    onChange={(e) => setDatePaid(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={clientEmail || email}
                    onChange={(e) => setClientEmail(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="mb-3 d-flex justify-content-end">
                  <button
                    className="btn primary-bg-color text-white py-3 btn-lg"
                    type="submit"
                  >
                    Proceed <ArrowRight />
                  </button>
                </div>
              </form>
            </>
          ) : (
            <>
              <form>
                <div className="mb-3 border p-3">
                  <small className="text-muted">Name</small>
                  <EditText
                    name="clientname"
                    value={updatedClientName || clientname}
                    onChange={setUpdatedClientName}
                    onSave={handleUpdate}
                  />
                </div>
                <div className="mb-3 border p-3">
                  <small htmlFor="" className="text-muted">
                    Email
                  </small>
                  <br />
                  <EditText
                    name="email"
                    value={updatedEmail || email}
                    onChange={setUpdatedEmail}
                    onSave={handleUpdate}
                  />
                </div>
                <div className="">
                  <div className="row g-0">
                    <div className="col-md-6 border p-3 mb-3">
                      <small htmlFor="" className="text-muted">
                        Phone
                      </small>
                      <br />
                      <EditText
                        name="phone"
                        type="number"
                        value={updatedPhone || phone}
                        onChange={setUpdatedPhone}
                        onSave={handleUpdate}
                      />
                    </div>
                    <div className="col-md-6 border p-3 mb-3">
                      <small htmlFor="" className="text-muted">
                        Other phone
                      </small>
                      <br />
                      <EditText
                        name="otherPhone"
                        type="number"
                        value={updatedOtherPhone || otherPhone}
                        onChange={setUpdatedPhone}
                        onSave={handleUpdate}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3 border p-3">
                  <small htmlFor="" className="text-muted">
                    Amount
                  </small>
                  <br />
                  <EditText
                    name="amount"
                    type="number"
                    value={updatedAmount || amount}
                    onChange={setUpdatedAmount}
                    onSave={handleUpdate}
                  />
                </div>
                <div className="mb-3 border p-3">
                  <small htmlFor="" className="text-muted">
                    Address
                  </small>
                  <br />
                  <EditText
                    name="address"
                    value={updatedAddress || address}
                    onChange={setUpdatedAddress}
                    onSave={handleUpdate}
                  />
                </div>
              </form>
              <div className="mb-3 d-flex justify-content-between">
                <div>
                  <button
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.value) {
                          deleteClient(cyclopID);
                          handleClose();
                        }
                      });
                    }}
                    className="btn py-3 btn-danger"
                    type="button"
                  >
                    <Trash /> Delete client
                  </button>
                </div>
                <button
                  className="btn primary-bg-color text-white py-3 mx-1"
                  onClick={handleGenerate}
                >
                  <Receipt /> Generate receipt
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

ClientItem.propTypes = {
  client: PropTypes.object.isRequired,
  updateClient: PropTypes.func.isRequired,
  clientPay: PropTypes.func.isRequired,
  deleteClient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  updateClient,
  clientPay,
  deleteClient,
})(ClientItem);
