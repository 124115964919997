import React, { useState } from "react";
import {  Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import logo from "../../logo.png";

const Login = ({ login, isAuthenticated, loading }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const { username, password } = formData;


  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault()
    login(username, password);
  }

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="offset-md-4 col-md-4 mt-5">
          <div className="mb-3 d-flex justify-content-center">
            <img src={logo} alt="" className="img-fluid w-50" loading="lazy" />
          </div>
          <form
            action=""
            method="post"
            className="card card-body shadow rounded-4 p-5"
            onSubmit={onSubmit}
          >
            <div className="mb-3">
              <input
                type="text"
                placeholder="Username"
                name="username"
                value={username}
                onChange={onChange}
                className="form-control py-3 rounded-pill text-center"
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={onChange}
                className="form-control py-3 rounded-pill text-center"
              />
            </div>
            <div className="mb-3 d-grid">
              <button
                type="submit"
                className="btn primary-bg-color text-white py-4 rounded-pill"
              >
                {loading ? (
                  <div className="spinner-border text-light " role="status" style={{
                    height: "1rem",
                    width: "1rem",
                  }}>
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <span>LOG IN</span>
                )}
              </button>
            </div>
            <div className="mb-3">
              <p className="text-center">
                <Link to="/forgot-password" className="text-decoration-none">Forgot your password?</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
