import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AdminLayout from '../../components/layout/AdminLayout';
import { clientPay } from '../../actions/receipt';
import { connect } from 'react-redux';

const GenerateReceipt = ({ clientPay }) => {
  const [amount, setAmount] = useState('');
  const [receivedFrom, setReceivedFrom] = useState('');
  const [paymentFor, setPaymentFor] = useState('');
  const [chequeNo, setChequeNo] = useState('');

  const [email, setEmail] = useState('');

  const history = useHistory();

  useEffect(() => {
    setReceivedFrom(localStorage.getItem('receivedFrom'));
    setPaymentFor('Security guard');
    setEmail(localStorage.getItem('customerEmail'));
  }, []);

  return (
    <AdminLayout>
      <form
        className="container"
        onSubmit={(e) => {
          e.preventDefault();
          clientPay({
            amount,
            receivedFrom,
            paymentFor,
            chequeNo,
            email
          });
          history.push('/receipts');
        }}
      >
        <div className="mb-4 px-4">
          <div className="row">
            <div className="col-md-6">
              <span className="fs-4 text-white">Generate receipt</span>
            </div>
            <div className="col-md-6">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <Link
                      to="/receipts"
                      className="text-muted text-decoration-none"
                    >
                      Receipts
                    </Link>
                  </li>
                  <li class="breadcrumb-item text-light" aria-current="page">
                    generate receipt
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="card shadow rounded-4">
              <div className="card-body p-5">
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Amount
                  </label>
                  <input
                    type="number"
                    className={`form-control py-4`}
                    name="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Received with thanks from
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="receivedFrom"
                    value={receivedFrom}
                    onChange={(e) => setReceivedFrom(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Being payment for
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={paymentFor}
                    onChange={(e) => setPaymentFor(e.target.value)}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Cash / Cheque No.
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={chequeNo}
                        onChange={(e) => setChequeNo(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card shadow rounded-4 mb-3">
              <div className="card-body p-5">
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Client email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="form-text">A receipt will be sent to the above email address.</div>
                </div>
              </div>
            </div>
            <div className="mb-3 d-grid">
              <button
                className="btn primary-bg-color text-white rounded-3 py-4"
                type="submit"
              >
                Generate & send
              </button>
            </div>
          </div>
        </div>
      </form>
    </AdminLayout>
  );
};

GenerateReceipt.propTypes = {
  clientPay: PropTypes.func.isRequired
};

export default connect(null, { clientPay })(GenerateReceipt);
