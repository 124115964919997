import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getClients, deleteClient } from "../../actions/client";
import AdminLayout from "../../components/layout/AdminLayout";
import ClientItem from "./ClientItem";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { PersonPlusFill } from "react-bootstrap-icons";
import MediaQuery from "react-responsive";

const Clients = ({
  getClients,
  deleteClient,
  client: { clients, loading },
}) => {
  useEffect(() => {
    getClients();
  }, [getClients]);

  return (
    <AdminLayout>
      <div className="container mb-5">
        <div className="mb-4 px-3">
          <div className="d-flex justify-content-between">
            <div className="fs-4 text-white my-2">Clients</div>
            <Link
              to="/clients/create"
              className="btn text-white btn-lg px-3 rounded-3"
            >
              <PersonPlusFill />
              <MediaQuery query="(min-width: 992px)">
                <span className="mx-2">Register client</span>
              </MediaQuery>
            </Link>
          </div>
        </div>
        <div className="card shadow rounded-4">
          <div className="card-body p-4">
            <div className="d-flex justify-content-end mb-3"></div>
            {loading ? (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            ) : (
              clients.map((client) => (
                <ClientItem key={client.cyclopID} client={client} />
              ))
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

Clients.propTypes = {
  auth: PropTypes.object.isRequired,
  getClients: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  deleteClient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  client: state.client,
  auth: state.auth,
});

export default connect(mapStateToProps, { getClients, deleteClient })(Clients);
