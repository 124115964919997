import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Receipt,
  Person,
  Speedometer2,
  PeopleFill,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import { ListGroup, Modal } from "react-bootstrap";
import { logout } from "../../actions/auth";
import { connect } from "react-redux";

const NavItem = ({ path, icon, name }) => {
  return (
    <Link to={path} className="nav__link">
      <span className="nav__icon">{icon}</span>
      <span className="nav__text">{name}</span>
    </Link>
  );
};
const adminLevel = localStorage.getItem("adminLevel");

const MobileNavbar = ({ logout }) => {
  const links = [
    {
      name: "Dashboard",
      icon: <Speedometer2 />,
      path: "/dashboard",
    },
    {
      name: "Clients",
      icon: <PeopleFill />,
      path: "/clients",
    },
    {
      name: "Receipts",
      icon: <Receipt />,
      path: "/receipts",
    },
  ];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <nav
        className="nav-fixed shadow px-3"
        style={{
          borderTopLeftRadius: "30px",
          borderTopRightRadius: "30px",
        }}
      >
        {links.map((link, index) => (
          <NavItem
            key={index}
            name={link.name}
            icon={link.icon}
            path={link.path}
          />
        ))}
        <a href="#!" onClick={handleShow} className="nav__link">
          <span className="nav__icon">
            <Person />
          </span>
          <span className="nav__text">Account</span>
        </a>
      </nav>
      <Modal show={show} onHide={handleClose} animation={true} centered>
        <ListGroup>
          <ListGroup.Item>
            <Link to="/settings" className="text-decoration-none text-dark">Settings</Link>
          </ListGroup.Item>
          {adminLevel && adminLevel === "alpha" ? (
            <ListGroup.Item>
              <Link to="/users" className="text-decoration-none text-dark">Users</Link>
            </ListGroup.Item>
          ) : null}
          <ListGroup.Item action onClick={logout}>
            Signout
          </ListGroup.Item>
        </ListGroup>
      </Modal>
    </>
  );
};

MobileNavbar.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { logout })(MobileNavbar);
