import {
  GET_ANALYTICS_FAILURE,
  GET_ANALYTICS_REQUEST,
  GET_ANALYTICS_SUCCESS,
} from "../types/analytics";

const initialState = {
  analytics: [],
  loading: true,
  error: {},
};

function analyticsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics: payload,
        loading: false,
      };
    case GET_ANALYTICS_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default analyticsReducer;
