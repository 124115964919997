import React, { useState } from "react";
import PropTypes from "prop-types";
import logo from "../../logo.png";
import { forgotPassword } from "../../actions/auth";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ArrowRight } from "react-bootstrap-icons";

const ForgotPassword = ({ forgotPassword, loading }) => {
  const [formData, setFormData] = useState({
    username: "",
  });

  const { username } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    forgotPassword({ username });
    setFormData({
      username: "",
    });
  };
  return (
    <div className="container">
      <div className="row">
        <div className="offset-md-4 offset-sm-4 col-md-4 col-sm-4 mt-5">
          <div className="mb-3 d-flex justify-content-center">
            <img src={logo} alt="" className="img-fluid w-50" loading="lazy" />
          </div>
          <form
            action=""
            method="post"
            className="card card-body shadow rounded-4 p-5"
            onSubmit={onSubmit}
          >
            <div className="mb-3">
              <label htmlFor="" className="form-label">
                We require your username to reset the password.
              </label>
              <div className="d-flex">
                <input
                  type="text"
                  placeholder="Username"
                  name="username"
                  value={username}
                  onChange={onChange}
                  className="form-control py-3 rounded-pill text-center"
                />

                <button
                  type="submit"
                  className="btn primary-bg-color text-white py-3 px-4 mx-2 rounded-pill"
                >
                  {loading ? (
                    <div
                      className="spinner-border text-light "
                      role="status"
                      style={{
                        height: "1rem",
                        width: "1rem",
                      }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <span>
                      <ArrowRight />
                    </span>
                  )}
                </button>
              </div>
            </div>
            <div className="mb-3 d-grid"></div>
            <div className="mb-3">
              <p className="text-center">
                <Link to="/" className="text-decoration-none">
                  Back to login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
