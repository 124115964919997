import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";

import Navbar from "./Navbar";
import MobileNavbar from "./MobileNavbar";

const AdminLayout = ({ children, auth: { isAuthenticated } }) => {
  return (
    <>
      <span className="screen-darken"></span>
      <Navbar />
      <div className="primary-bg-color h-25v py-5 mb-5"
      style={{
        marginBottom: '500px'
      }}
      >{children}</div>
      <MediaQuery query="(max-width: 991px)">
        <MobileNavbar />
      </MediaQuery>
    </>
  );
};

AdminLayout.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(AdminLayout);
