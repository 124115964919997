import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addClient } from '../../actions/client';
import AdminLayout from '../../components/layout/AdminLayout';
import { Link } from 'react-router-dom';
import { ArrowRight, ArrowRightShort, Dot } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';

const ClientsEdit = ({ addClient }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [altPhone, setAltPhone] = useState('');
  const [address, setAddress] = useState('');

  const history = useHistory();

  const onSubmit = (e) => {
    e.preventDefault();
    addClient({ name, email, phone, altPhone, address });
    setName('');
    setEmail('');
    setPhone('');
    setAltPhone('');
    setAddress('');
    history.push('/clientss/create/add-amount-details');
    // return <Redirect to="/clientss/create/add-amount-details" />;
  };

  return (
    <AdminLayout>
      <div className="container">
        <div className="mb-4 px-4">
          <div className="row">
            <div className="col-md-4">
              <span className="fs-5 text-white">Register new clients</span>
            </div>
            <div className="col-md-4">
              <div className="d-flex">
                <div className="text-white">
                  <Dot className="fs-2" />
                  <small>Personal details</small>
                </div>
                <ArrowRight className="my-2 text-muted mx-3" />
                <div className="text-muted">
                  <Dot className="text-white fs-2" />
                  <small>Amount details</small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <Link
                      to="/clientss"
                      className="text-white text-decoration-none"
                    >
                      Clientss
                    </Link>
                  </li>
                  <li class="breadcrumb-item text-light" aria-current="page">
                    Register new clients
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="card shadow rounded-4">
          <div className="card-body p-5">
            <form action="" onSubmit={onSubmit}>
              <div className="mb-3 row">
                <div className="col-md-6">
                  <label htmlFor="" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control py-3"
                    value={name}
                    name={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    required
                    className="form-control py-3"
                    value={email}
                    name={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col-md-6">
                  <label htmlFor="" className="form-label">
                    Phone number
                  </label>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      +256
                    </span>
                    <input
                      type="text"
                      class="form-control py-3"
                      placeholder="703050056"
                      name={phone}
                      value={phone}
                      maxLength="9"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="form-label">
                    Alternative phone number
                  </label>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      +256
                    </span>
                    <input
                      type="text"
                      class="form-control py-3"
                      placeholder="703050056"
                      maxLength="9"
                      name={altPhone}
                      value={altPhone}
                      onChange={(e) => setAltPhone(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="" className="form-label">
                  Physical address
                </label>
                <textarea
                  className="form-control"
                  id=""
                  cols="30"
                  rows="3"
                  name={address}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></textarea>
              </div>
              <div className="d-flex justify-content-end">
                <button className="btn btn-secondary px-4 py-3" type="submit">
                  Proceed <ArrowRightShort className="mx-3" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

ClientsEdit.propTypes = {
  addClient: PropTypes.func.isRequired
};

export default connect(null, { addClient })(ClientsEdit);
