// import { lazy } from "react";
import ClientCreate from "../../pages/clients/ClientCreate";
import ClientEdit from "../../pages/clients/ClientEdit";
import Clients from "../../pages/clients/Clients";
import Dashboard from "../../pages/dashboard/Dashboard";
import GenerateReceipt from "../../pages/receipts/GenerateReceipt";
import Receipts from "../../pages/receipts/Receipts";
import Settings from "../../pages/settings/Settings";
import UserCreate from "../../pages/users/UserCreate";
import Users from "../../pages/users/Users";

export const routes = [
  {
    id: 2,
    path: "/dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    id: 3,
    path: "/clients",
    component: Clients,
    exact: true,
  },
  {
    id: 4,
    path: "/receipts",
    component: Receipts,
    exact: true,
  },
  {
    id: 5,
    path: "/settings",
    component: Settings,
    exact: true,
  },
  {
    id: 6,
    path: "/users",
    component: Users,
    exact: true,
  },
  {
    id: 7,
    path: "/users/create",
    component: UserCreate,
  },
  {
    id: 8,
    path: "/clients/create",
    component: ClientCreate,
    exact: true,
  },
  {
    id: 9,
    path: "/receipts/generate",
    component: GenerateReceipt,
  },
  {
    id: 11,
    path: "/clients/:id/edit",
    component: ClientEdit,
  },
];
