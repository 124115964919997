import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addClient } from "../../actions/client";
import AdminLayout from "../../components/layout/AdminLayout";
import { Link } from "react-router-dom";
import { ArrowLeftCircle, ArrowRightShort } from "react-bootstrap-icons";
import MediaQuery from "react-responsive";

const ClientCreate = ({ addClient, client: { loading } }) => {
  const [clientname, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [otherPhone, setOtherPhone] = useState("");
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");

  const token = localStorage.getItem("token");
  const adminUsername = localStorage.getItem("adminUsername");

  const onSubmit = (e) => {
    e.preventDefault();
    addClient({
      token,
      adminUsername,
      clientname,
      email,
      otherPhone: `+256` + otherPhone,
      phone: `+256` + phone,
      address,
      amount,
    });
    setClientName("");
    setEmail("");
    setPhone("");
    setOtherPhone("");
    setAddress("");
    setAmount("");
  };

  return (
    <AdminLayout>
      <div className="container">
        <div className="mb-4 px-4">
          <div className="d-flex justify-content-between">
            <div className="fs-5 text-white my-2">Register new client</div>
            <Link
              to="/clients"
              className="btn text-white btn-lg px-3 rounded-3"
            >
              <ArrowLeftCircle size={30} />
              <MediaQuery query="(min-width: 992px)">
                <span className="mx-2">Back to list</span>
              </MediaQuery>
            </Link>
          </div>
        </div>
        <form action="" onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-8">
              <div className="card shadow rounded-4 mb-3">
                <div className="card-body p-5">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control py-3"
                      value={clientname}
                      name={clientname}
                      placeholder="firstname, lastname"
                      onChange={(e) => setClientName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="" className="form-label">
                        Phone number
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          +256
                        </span>
                        <input
                          type="text"
                          className="form-control py-3"
                          placeholder="703050056"
                          name={phone}
                          value={phone}
                          maxLength="9"
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="form-label">
                        Other phone number
                      </label>
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">
                          +256
                        </span>
                        <input
                          type="text"
                          className="form-control py-3"
                          placeholder="703051256"
                          name={otherPhone}
                          value={otherPhone}
                          maxLength="9"
                          onChange={(e) => setOtherPhone(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-12">
                      <label htmlFor="" className="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        required
                        className="form-control py-3"
                        value={email}
                        name={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span className="form-text">
                        NOTE: Sends a confirmation email to this client email.
                      </span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Physical address
                    </label>
                    <textarea
                      className="form-control"
                      id=""
                      cols="30"
                      rows="3"
                      name={address}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow rounded-4 mb-3">
                <div className="card-body p-5">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Amount
                    </label>
                    <input
                      type="number"
                      className="form-control py-3"
                      placeholder="0"
                      name="amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    <span className="form-text">
                        Set an amount the client is to pay monthly.
                      </span>
                  </div>
                </div>
              </div>
              <div
                className="d-grid"
                style={{
                  marginBottom: "5rem",
                }}
              >
                <button
                  className="btn primary-bg-color text-white btn-lg px-4 py-3"
                  type="submit"
                >
                  {loading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <span>
                      Register new client <ArrowRightShort className="mx-3" />
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </AdminLayout>
  );
};

ClientCreate.propTypes = {
  addClient: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  client: state.client,
});

export default connect(mapStateToProps, { addClient })(ClientCreate);
