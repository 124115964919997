import {
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../types/user";

const initialState = {
  users: [],
  user: null,
  loading: true,
  error: {},
};

function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: payload,
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case REGISTER_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
      };
    // return {
    //   ...state,
    //   users: [payload, ...state.users],
    //   loading: false
    // };
    case REGISTER_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      const { username, email, name } = payload;
      const user = state.users.find((user) => user.username === username);
      user.fullName = name;
      user.email = email;
      return {
        ...state,
        loading: false,
        user: payload,
      };

    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.username !== payload),
        loading: false,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default userReducer;
