import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import AdminLayout from "../../components/layout/AdminLayout";
import { getUsers, deleteUser } from "../../actions/user";
import { connect } from "react-redux";
import UserItem from "./UserItem";
import { PersonPlusFill } from "react-bootstrap-icons";
// Deleted Pencil, TRash from the top line
import MediaQuery from "react-responsive";
import Skeleton from "react-loading-skeleton";
const Users = ({ getUsers, user: { users, loading } }) => {
  useEffect(() => {
    getUsers();
  }, [getUsers]);
  return (
    <AdminLayout>
      <div className="container">
        <div className="mb-4 px-3">
          <div className="d-flex justify-content-between">
            <div className="fs-4 text-white my-2">Users</div>
            <Link
              to="/users/create"
              className="btn text-white btn-lg px-3 rounded-3"
            >
              <PersonPlusFill />
              <MediaQuery query="(min-width: 992px)">
                <span className="mx-2">Register user</span>
              </MediaQuery>
            </Link>
          </div>
        </div>
        <div className="card shadow rounded-4">
          <div className="card-body p-4">
            {loading ? (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            ) : (
              users.map((user) => <UserItem key={user.username} user={user} />)
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

Users.propTypes = {
  getUsers: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { getUsers, deleteUser })(Users);
