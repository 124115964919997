import React from "react";

const NotFound = () => {
  return (
    <>
      <div className="bg-light p-5 m-5">
        <span className="fs-2">404</span>
        <br />
        <span className="fs-4">The page you requested for does not exist</span>
      </div>
    </>
  );
};

export default NotFound;
