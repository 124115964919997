import {
  GET_ANALYTICS_FAILURE,
  GET_ANALYTICS_REQUEST,
  GET_ANALYTICS_SUCCESS,
} from "../types/analytics";
import api from "../utils/api";
import { setParams } from "../utils/setParams";

export const getAnalytics = () => async (dispatch) => {
  dispatch({
    type: GET_ANALYTICS_REQUEST,
  });
  try {
    const res = await api.get("/analytics", setParams());
    console.log(res.data)
    dispatch({
      type: GET_ANALYTICS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ANALYTICS_FAILURE,
      payload: { msg: "Unable to fetch the analytics data.", status: false },
    });
  }
};
