import { GET_CLIENT_FAILURE } from "../types/client";
import {
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../types/user";
import api from "../utils/api";
import { setParams } from "../utils/setParams";
import { setAlert } from "./alert";

// Get users
export const getUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_USERS_REQUEST,
    });
    const res = await api.get("/user/getAll", setParams());

    dispatch({
      type: GET_USERS_SUCCESS,
      payload: res.data.allUsers,
    });
  } catch (err) {
    dispatch({
      type: GET_CLIENT_FAILURE,
      payload: { msg: "Unable to fetch users", status: false },
    });
  }
};

// Get user
export const getUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_REQUEST,
    });
    const res = await api.get(`/users/${id}`);

    dispatch({
      type: GET_USER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_FAILURE,
      payload: { msg: "Unable to fetch user details", status: false },
    });
  }
};

// Add user
export const addUser = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: REGISTER_USER_REQUEST,
    });
    const res = await api.post("/register", formData, {
      headers: {
        "x-api-key": "7qe2F4XGNA2Kg9BCpSbWW9pNkLKkFJbNMXnbzof2",
      },
    });

    dispatch({
      type: REGISTER_USER_SUCCESS,
      payload: res.data,
    });

    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert("Unable to register user", "danger"));
    dispatch({
      type: REGISTER_USER_FAILURE,
      payload: { msg: err, status: false },
    });
  }
};

// Update user
export const updateUser =
  ({ ...formData }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_USER_REQUEST,
      });
      const res = await api.patch(`/users`, formData, {
        headers: {
          "x-api-key": "7qe2F4XGNA2Kg9BCpSbWW9pNkLKkFJbNMXnbzof2",
        },
      });
      console.log(res.data);
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: res.data,
      });
      dispatch(setAlert(res.data.message, "success"));
    } catch (err) {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: { msg: err, status: false },
      });
    }
  };

// Delete user
export const deleteUser = (username) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_USER_REQUEST,
    });
    const res = await api.delete(`/users?username=${username}`, setParams());
    console.log(res)
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: username,
    });

    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch({
      type: DELETE_USER_FAILURE,
      payload: { msg: err, status: false },
    });
  }
};
