import React, { useEffect } from "react";
//commented out lazy
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Alert from "./components/layout/Alert";
import Login from "./pages/auth/Login";
import { routes } from "./components/routing/routes";
import NotFound from "./pages/notfound/NotFound";
import PrivateRoute from "./components/routing/PrivateRoute";
// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import { LOGOUT } from "./types/auth";
import ForgotPassword from "./pages/auth/ForgotPassword";

const App = () => {
  // const [user, setuser] = useState()
  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      // setuser(true);
    }
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <>
          <Switch>
            {routes.map((route) => {
              return <PrivateRoute key={route.id} {...route} />;
            })}
            <Route path="/" component={Login} exact />
            <Route
              path="/forgot-password"
              component={ForgotPassword}
              exact
            />
            <Route component={NotFound} />
          </Switch>
        </>
        <Alert />
      </Router>
    </Provider>
  );
};

export default App;
