export function setParams() {
    const username = localStorage.getItem("adminUsername");
    const token = localStorage.getItem("token");
    return {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      params: {
        adminUsername: username,
        token: token,
      },
    };
  }