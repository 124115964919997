import React from 'react';
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AdminLayout from '../../components/layout/AdminLayout';
import { changePassword } from '../../actions/auth';
// import { ArrowRight } from 'react-bootstrap-icons';

const Settings = ({ changePassword }) => {
  // const [password, setPassword] = useState('');
  return (
    <AdminLayout>
      <div className="container">
        <div className="mb-4 px-4">
          <div className="row">
            <div className="col-md-6">
              <span className="fs-4 text-white">Settings</span>
            </div>
          </div>
        </div>
        {/* <div className="card shadow rounded-4">
          <div className="card-body p-5">
            <div className="row">
              <div className="col-md-4">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h4 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        Set new secure password
                      </button>
                    </h4>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <form
                          className="form"
                          onSubmit={(e) => {
                            e.preventDefault();
                            changePassword({ password });
                            setPassword('');
                          }}
                        >
                          <div className="mb-3">
                            <input
                              type="password"
                              className="form-control py-3"
                              name="password"
                              placeholder="use more than 6 characters"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </div>
                          <div className="mb-3 d-grid">
                            <button className="btn text-white primary-bg-color py-3" type="submit">
                              Proceed <ArrowRight className="mx-3" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <span className="mx-4 text-light">Contact us and suggest a feature update</span>
      </div>
    </AdminLayout>
  );
};

Settings.propTypes = {
  changePassword: PropTypes.func.isRequired
};

export default connect(null, { changePassword })(Settings);
