import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import receipt from "./receipt";
import client from "./client";
import user from "./user";
import analytics from "./analytics";

export default combineReducers({
  alert,
  auth,
  receipt,
  client,
  user,
  analytics,
});
