export const GET_RECEIPTS_REQUEST = 'GET_RECEIPTS_REQUEST';
export const GET_RECEIPTS_SUCCESS = 'GET_RECEIPTS_SUCCESS';
export const GET_RECEIPTS_FAILURE = 'GET_RECEIPTS_FAILURE';

export const GET_RECEIPT_REQUEST = 'GET_RECEIPT_REQUEST';
export const GET_RECEIPT_SUCCESS = 'GET_RECEIPT_SUCCESS';
export const GET_RECEIPT_FAILURE = 'GET_RECEIPT_FAILURE';

export const GENERATE_RECEIPT_REQUEST = 'GENERATE_RECEIPT_REQUEST';
export const GENERATE_RECEIPT_SUCCESS = 'GENERATE_RECEIPT_SUCCESS';
export const GENERATE_RECEIPT_FAILURE = 'GENERATE_RECEIPT_FAILURE';

export const DELETE_RECEIPT_REQUEST = 'DELETE_RECEIPT_REQUEST';
export const DELETE_RECEIPT_SUCCESS = 'DELETE_RECEIPT_SUCCESS';
export const DELETE_RECEIPT_FAILURE = 'DELETE_RECEIPT_FAILURE';

export const RESEND_RECEIPT_REQUEST = 'RESEND_RECEIPT_REQUEST';
export const RESEND_RECEIPT_SUCCESS = 'RESEND_RECEIPT_SUCCESS';
export const RESEND_RECEIPT_FAILURE = 'RESEND_RECEIPT_FAILURE';